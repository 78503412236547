import React from "react";
import { createPortal } from "react-dom";

export const BlurModal = ({ isModalOpen, onClose, children }) => {
  if (!isModalOpen) {
    return null;
  }

  return createPortal(
    <section className="  ">
      <div
        className="fixed h-screen w-screen z-[52] top-0 left-0  backdrop-blur-[5px] backdrop-brightness-50 overflow-hidden"
        onClick={onClose}
      ></div>
      <div
        // onWheel={() => {
        //   return false;
        // }}
        className="overflow-y-scroll modal-scroll max-h-screen fixed z-[55] md:top-1/2 top-0 md:left-1/2 md:-translate-x-1/2 md:-translate-y-72 md:w-auto w-full
        "
      >
        {children}
      </div>
    </section>,
    document.getElementById("modal-root")
  );
};

export const OpaqueModal = ({ isModalOpen, onClose, children }) => {
  if (!isModalOpen) {
    return null;
  }

  return createPortal(
    <section className={`fixed top-0 left-0 z-50 w-screen h-screen  `}>
      <div className=" opacity-40 bg-black w-screen h-screen"></div>
      <div className="absolute  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
        {children}
      </div>
    </section>,
    document.getElementById("modal-root")
  );
};
