import React, { Fragment } from 'react'
import PrimaryButton from '../../components/form/PrimaryButton'
import Navigation from '../../Layout/Navigation'

const OrderHistory = () => {
    const order_history = [
        {
            id: '1',
            order: '3000 rounds of Shege with stew'
        },

        {
            id: '2',
            order: '100 plates of rice and chivita'
        },
        {
            id: '3',
            order: '700 bags of Ayo and Netflix'
        },
        {
            id: '4',
            order: '100 spoons of garri'
        },
        {
            id: '5',
            order: '1000 rounds of Shege'
        },
        {
            id: '6',
            order: 'FYI, you will face Shege'
        }
    ]
    return (
        <Fragment>
            <Navigation />
            <main className='mt-28 px-5 md:px-32'>
                <section className='mb-20'>
                    <p className='font-semibold text-start text-[#A8ABB2] text-xl md:text-2xl lg:text-3xl'>Order History</p>
                </section>

                <section>
                        {
                            order_history.map((order) => {
                                return (
                                    <section key={order.id}>
                                        <div className='w-full flex justify-between items-center mb-3 py-3'>
                                                <p className='text-start text-[14x] md:text-lg font-medium md:font-semibold w-[1/2] md:w-fit'>{order.order}</p>
                                                <div className='w-[150px] md:w-[200px]'>
                                                    <PrimaryButton handle={() => ''}>Re-Order</PrimaryButton>
                                                </div>
                                                
                                                
                                        </div>
                                        <hr></hr>
                                    </section>
                                    
                                )
                            })
                        }
                </section>
            </main>
        </Fragment>
        )
}

export default OrderHistory