import React, { useContext } from 'react'
import TextField from '../../components/form/TextField'
import { ReactComponent as Envelope } from "../../assets/envelope-icon.svg";
import PrimaryButton from '../../components/form/PrimaryButton';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import ModalContext from '../../contexts/ModalContext';

const VerifyPassword = () => {
    const [email, setEmail] = React.useState("")
    const { setAlertProps, setIsVerifyModalOpen } = useContext(ModalContext)
    //const [isLoading, setIsLoading] = React.useState(false)

    const handleInputChange = (e) => {
        e.preventDefault()
        setEmail(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        verify()



    }

    const { mutate: verify,  isLoading } = useMutation(
        () => {
            const payload = {
                email
            };
            return axios.post(`${process.env.REACT_APP_BASE_URI}/auth/resend_verification_mail`, payload)
        },
        {
            onSuccess: (response) => {
                setIsVerifyModalOpen(false)
                setAlertProps({
                    type: "success",
                    title: "Success",
                    subtitle: response?.data?.message,
                    });
                    setTimeout(() => {
                        setAlertProps({
                        type: "",
                        title: "",
                        subtitle: null,
                        });
                    }, 10000);
            },
            onError: (error) => {

                setAlertProps({
                    type: "fail",
                    title: "Error",
                    subtitle: error?.response?.data?.message,
                    });
                    setTimeout(() => {
                    setAlertProps({
                        type: "",
                        title: "",
                        subtitle: null,
                    });
                    }, 10000);
                }
        }
    )
    return (
        <React.Fragment>
            <div className='bg-[#F2F2F2]  px-5 py-7 w-[300px] rounded-[36px] md:w-[650px]'>
            <h1 className="text-2xl text-center text-[#282B30] font-bold mb-1 md:text-3xl">Verify Email</h1>
            <h2 className="text-center text-[#282B30] mb-12">Fill in your email address you
                used to sign up. </h2>

                <form className="md:w-[400px] w-full mx-auto">
                    <div className="w-full h-14 mb-9">
                        <TextField
                        name="email"
                        type="text"
                        placeholder="Enter email address"
                        handleInputChange={handleInputChange}
                        value={email}
                        icon={<Envelope />}
                        />
                    </div>

                    <div className="w-full h-14 mb-10">
                        <PrimaryButton
                        handle={handleSubmit}
                        isButtonDisabled={!!isLoading}
                        isButtonLoading={isLoading ? true : false}
                        >
                        SEND
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default VerifyPassword
