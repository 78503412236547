import React from "react";
import errorImg from "../assets/error.svg";
import Navigation from "../Layout/Navigation";
import Footer from "../Layout/Footer";
import arrowIcon from '../assets/arrowIcon.svg';
import { useNavigate } from "react-router-dom";
const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navigation />
      <section className="mt-32 min-h-screen">
        <div className="lg:w-5/6 w-11/12 mx-auto">
          <div className="flex items-center justify-between flex-col-reverse lg:flex-row">
            <div className="lg:text-left text-center lg:w-5/12 w-full">
              <h1 className="text-primary text-4xl font-bold">Oops!! Page Not Found.</h1>
              <p className="my-6">Unfortunately the content you are looking for is not here. There may be a misspelling in your web address, or you may have clicked a link for content that no longer exists. </p>
              <button
              onClick={() => navigate('/')}
              className="py-3 px-12 rounded-full uppercase text-primary border-primary border-solid border bg-transparent my-8"><img src={arrowIcon} className="inline -mt-1 mr-1 text-primary"

              alt=""/> back to Homepage</button>
            </div>
            <div className="md:w-6/12 w-full">
              <img src={errorImg} className="w-full object-cover" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ErrorPage;
