import React from "react";

const Footer = () => {
  return (
    <footer className="w-full border-t-2 border-lightLine py-4">
      <p className="text-sm text-center">
        © copyright 2021 Tasteclan. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
