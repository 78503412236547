import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import ModalContext from "../../../contexts/ModalContext";

const { REACT_APP_BASE_URI } = process.env;
const completeOrderUrl = `${REACT_APP_BASE_URI}/checkout/complete_order`;

//const accessToken = sessionStorage.getItem('access_token')

const useCompleteOrd = (payload, accessToken) => {
  const { setAlertProps } = useContext(ModalContext);

  return useMutation(
    () => {
      return axios.post(completeOrderUrl, payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    },
    {
      onMutate: () => {
        //console.log('access token', accessToken)
      },
      onSuccess: (response) => {
        window.location.href = response.data.payment_link;
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      },
      onError: (error) => {
        console.log(error.message);
        setAlertProps({
          type: "fail",
          title: error?.response?.data?.message,
          subtitle: "",
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      },
    }
  );
};

export default useCompleteOrd;
