import React from "react";
import toasterSucc from "../../assets/toasterSucc.svg";
import toasterFail from "../../assets/toasterFail.svg";

const CustomAlert = ({ type, title, subtitle }) => {
  if (type === "success") {
    return (
      <div className="bg-[#FFF6F3] z-[56] fixed bottom-4 left-0 md:w-96 w-80 ml-4 border-l-8 border-[#009C87] rounded-xl p-6 flex items-center gap-4 toast">
        <div className="w-8">
          <img src={toasterSucc} className="w-full object-cover" alt="" />
        </div>
        <div className="text-[#7B7B7B] text-left">
          <h3 className="text-lg font-semibold">{title}</h3>
          <p className="text-xs">{subtitle}</p>
        </div>
      </div>
    );
  } else if (type === "fail") {
    return (
      <div className="bg-[#FFF6F3] z-[56] fixed bottom-4 left-0 md:w-96 w-80 ml-4 border-l-8 border-[#FF2323] rounded-xl p-6 flex items-center gap-4 toast">
        <div className="w-8">
          <img src={toasterFail} className="w-full object-cover" alt="" />
        </div>
        <div className="text-[#7B7B7B] text-left">
          <h3 className="text-lg font-semibold">{title}</h3>
          <p className="text-xs">{subtitle}</p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default CustomAlert;
