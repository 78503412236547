import React, { useContext } from 'react';
import ModalContext from '../../contexts/ModalContext';
import PrimaryButton from '../../components/form/PrimaryButton';

const PreOrder = () => {
  const { setIsPreOrderOpen } = useContext(ModalContext);

  const handleProceedToPayment = () => {
    setIsPreOrderOpen(false);
  };

  return (
    <div className="bg-[#F2F2F2] relative px-5 py-7 md:rounded-[36px] md:w-[650px] w-full">
      <div>
        <h2 className='pb-7 text-center'>Your food is pre-ordered and will be delivered to you after 24 hours.</h2>
      </div>

      <div className=''>
        <PrimaryButton handle={handleProceedToPayment}>Ok</PrimaryButton>
      </div>
    </div>
  );
};

export default PreOrder;


