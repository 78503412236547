import React from "react";
import Navigation from "../Layout/Navigation";
import Footer from "../Layout/Footer";
import chicken from "../assets/crispy-fried-chicken-home.svg";
import pizza from "../assets/pizza-home.svg";
import right from "../assets/chevron-right.svg";
import pea from "../assets/pea-home.svg";
import topresIcon from "../assets/topresIcon.svg";
import lettuce from "../assets/lettuce.png";
import mobileHome from "../assets/mobile-home.svg";
import femaleMoji from "../assets/female-memoji.svg";
import email from "../assets/emailIcon.svg";
import instagram from "../assets/instagramIcon.svg";
import facebook from "../assets/facebookIcon.svg";
import twitter from "../assets/twitterIcon.svg";
// import Amala from "../assets/amala.png";
// import pancake from "../assets/pancake.jpg";
// import suyaville from "../assets/suyaville.jpg";
import TextField from "../components/form/TextField";

import { useMutation } from "@tanstack/react-query";
import ModalContext from "../contexts/ModalContext";
import PrimaryButton from "../components/form/PrimaryButton";
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import AuthContext from "../contexts/AuthContext";
import forkandknife from "../assets/forkandknife.svg";

// opening hours
import { openingHours } from "../utils/opening_hours";

const Home = () => {
  // modal context
  // const { setIsLoginModalOpen } = useContext(ModalContext);
  // auth context
  const { accessToken } = useContext(AuthContext);

  let navigate = useNavigate();

  const [inputValues, setInputValues] = useState({});
  const [restaurants, setRestaurants] = useState([]);
  const {
    // alertProps,
    setAlertProps,
  } = useContext(ModalContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };
  useEffect(() => {
    async function topRestaurant() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/vendors/get_top_resturants`
        );
        let topRes = await response?.data?.data;
        setRestaurants(topRes);
      } catch (error) {
        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: error?.response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      }
    }
    topRestaurant();
  }, [setAlertProps]);

  const fetchFoodItems = async (restaurant) => {
    const payload = {
      restuarant_id: restaurant._id,
    };
    const date = new Date();
    const hours = date.getHours();
    if (hours >= openingHours.startTime && hours <= openingHours.endTime) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URI}/vendors/fetch_restuarant_foods`,
          payload,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        const editFood = await response?.data?.data;
        editFood.forEach((food) => {
          return (food.quantity = 1);
        });
        editFood.forEach((food) => {
          return (food.active = false);
        });
        sessionStorage.setItem("foodItems", JSON.stringify(editFood));
        navigate(`/foodlist/${restaurant.restuarant_name}/${restaurant._id}`, {
          state: {
            monday: restaurant.schedule[0].monday,
            tuesday: restaurant.schedule[0].tuesday,
            wednesday: restaurant.schedule[0].wednesday,
            thursday: restaurant.schedule[0].thursday,
            friday: restaurant.schedule[0].friday,
            saturday: restaurant.schedule[0].saturday,
            sunday: restaurant.schedule[0].sunday,
          },
        });
      } catch (error) {
        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: error?.response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      }
    } else {
      navigate("/restaurants");
    }
  };

  const { mutate: sendMessage, isLoading: loadingMessage } = useMutation(
    () => {
      const body = {
        name: inputValues.name,
        email: inputValues.email,
        message: inputValues.message,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URI}/contact/send-message`,
        body
      );
    },
    {
      onSuccess: (response) => {
        setAlertProps({
          type: "success",
          title: "Success",
          subtitle: response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
        // alert(response?.data?.message);
      },
      onError: (error) => {
        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: error?.response?.data?.message,
        });
        // alert(error?.response?.data?.message);
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
        // alert(error?.response?.data?.message);
      },
      onMutate: () => { },
      onSettled: () => {
        document.getElementById("reset").reset();
      },
    }
  );
  const { mutate: subcribeUserApi, isLoading: loadingsubscribeApi } =
    useMutation(
      () => {
        const body = {
          email: inputValues.email,
        };
        return axios.post(
          `${process.env.REACT_APP_BASE_URI}/subscriber/add-subscriber`,
          body
        );
      },
      {
        onSuccess: (response) => {
          setAlertProps({
            type: "success",
            title: "Success",
            subtitle: response?.data?.message,
          });
          setTimeout(() => {
            setAlertProps({
              type: "",
              title: "",
              subtitle: null,
            });
          }, 5000);
          // alert(response?.data?.message);
        },
        onError: (error) => {
          setAlertProps({
            type: "fail",
            title: "Error",
            subtitle: error?.response?.data?.message,
          });
          // alert(error?.response?.data?.message);
          setTimeout(() => {
            setAlertProps({
              type: "",
              title: "",
              subtitle: null,
            });
          }, 5000);
          // alert(error?.response?.data?.message);
        },
        onMutate: () => { },
        onSettled: () => { },
      }
    );
  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage();
  };
  const subscribeUser = () => {
    subcribeUserApi();
  };

  return (
    <>
      <Navigation />
      <section className="pt-24 min-h-screen pb-12" id="top">
        <div className="relative mt-16">
          <div className="absolute lg:w-48 w-24 left-0 bottom-0 -mb-20">
            <img src={chicken} className="w-full object-cover" alt="" />
          </div>
          <div className="text-center -mt-12">
            <h1 className="text-[#7E8187] font-bold md:text-6xl text-5xl">
              You hungry?
            </h1>
            <h2
              // onClick={() => setIsLoginModalOpen(true)}
              className="lg:w-3/12 w-8/12 mx-auto my-4 text-[#7B7B7B] font-medium text-lg"
            >
              Order for food or foodstuff and get it within minutes
            </h2>
            <div className="w-56 h-12 mx-auto mb-4">
              <PrimaryButton
                handle={() => {
                  // accessToken
                  // ? 
                  navigate("/restaurants")
                  // : setIsLoginModalOpen(true);
                }}
              >
                place order
              </PrimaryButton>
            </div>
            <small className="block w-8/12 mx-auto text-[#7B7B7B]">
              Currently available in Akure, Ondo Nigeria.
            </small>
            <div className="w-full md:w-96 lg:w-4/12 mx-auto md:mt-20 mt-4 flex items-center justify-between bg-[#FFF4F1] p-6 rounded-md">
              <p className="font-bold text-[#585858]">Opening Hours:</p>
              <p>
                <span className="bg-[#FE724E] py-3 px-1 rounded-md text-[#fff] text-xl">
                  8:00<small className="text-sm">am</small>
                </span>{" "}
                -{" "}
                <span className="bg-[#FE724E] py-3 px-1 rounded-md text-[#fff] text-xl">
                  6:00<small className="text-sm">pm</small>
                </span>
              </p>
            </div>
          </div>
          <div className="absolute lg:w-60 w-28 right-0 bottom-0 -mb-40">
            <img src={pizza} className="w-full object-cover" alt="" />
          </div>
        </div>

        <div className="mt-60">
          <div className="lg:w-5/6 w-11/12 mx-auto">
            <h3 className="text-3xl text-left text-[#7E8187] font-bold">
              Top restaurants{" "}
            </h3>
            <div className="w-12 h-1 mt-2 bg-primary"></div>
          </div>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              400: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 2,
              },
              800: {
                slidesPerView: 3,
              },
              1300: {
                slidesPerView: 4,
              },
            }}
            className="lg:w-10/12 mt-16"
          >
            {restaurants.map((restaurant) => {
              return (
                <SwiperSlide
                  key={restaurant._id}
                  className="flex justify-center items-center"
                >
                  <div
                    style={{
                      backgroundImage: `url(${restaurant.restuarant_image})`,
                    }}
                    className={`h-56 rounded-2xl z-1 bg-cover`}
                  >
                    <div
                      onClick={() => {
                        // accessToken
                        //?
                        fetchFoodItems(restaurant)
                        //: setIsLoginModalOpen(true);
                      }}
                      className="z-2 bg-gradient-to-r from-[#00000042] to-[#00000042] h-full w-full px-3 py-3 rounded-2xl flex flex-col justify-end"
                    >
                      <div className="rounded-3xl bg-[#FFFFFF] cursor-pointer hover:bg-[#FFF1F1] border border-primary relative border-solid py-3 px-4 w-60 flex gap-4 items-center">
                        <div className="w-12">
                          <img
                            src={forkandknife}
                            alt=""
                            className="w-full object-cover"
                          />
                        </div>
                        <h4 className="font-bold text-[#4A4D53] text-sm">
                          {restaurant.restuarant_name}
                        </h4>
                        <div className="absolute right-0 w-16">
                          <img
                            src={topresIcon}
                            className="w-full object-cover"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="lg:w-5/6 w-11/12 mx-auto pt-24" id="joinClan">
          <div className="flex lg:flex-row flex-col justify-between items-center mb-16">
            <div className="md:w-6/12 w-full text-left">
              <div className="flex flex-wrap items-center lg:justify-start justify-center text-4xl text-[#7E8187] font-bold">
                <p className="pr-4">Don’t </p>
                <p className="scroller">
                  <span className="text-primary">
                    eat
                    <br />
                    grow
                    <br />
                    laugh
                    <br />
                    taste
                  </span>
                </p>
                <p className="pl-2">alone...</p>
              </div>
              <p className="mt-4 mb-12 text-[#7B7B7B] text-center lg:text-left">
                There’s a community made for you!{" "}
              </p>
              <div className="w-8/12 mx-auto lg:hidden block">
                <img src={mobileHome} className="w-full object-cover" alt="" />
              </div>
              <div className="flex flex-col lg:items-start items-center w-full">
                <div>
                  <div className="flex items-center gap-8 mt-8">
                    <div className="rounded-full bg-primary flex items-center justify-center w-5 h-5">
                      <img src={right} className="w-3 object-cover" alt="" />
                    </div>
                    <p className="text-[#7B7B7B]">
                      Do you have health goals you want to smash?
                    </p>
                  </div>
                  <div className="flex items-center gap-8 mt-8">
                    <div className="rounded-full bg-primary flex items-center justify-center w-5 h-5">
                      <img src={right} className="w-3 object-cover" alt="" />
                    </div>
                    <p className="text-[#7B7B7B]">
                      Tired of old recipes, need to try new ones?
                    </p>
                  </div>
                  <div className="flex items-center gap-8 mt-8">
                    <div className="rounded-full bg-primary flex items-center justify-center w-5 h-5">
                      <img src={right} className="w-3 object-cover" alt="" />
                    </div>
                    <p className="text-[#7B7B7B]">
                      You want to know more about what you eat?
                    </p>
                  </div>
                  <div className="flex items-center gap-8 mt-8">
                    <div className="rounded-full bg-primary flex items-center justify-center w-5 h-5">
                      <img src={right} className="w-3 object-cover" alt="" />
                    </div>
                    <p className="text-[#7B7B7B]">
                      Need a space to vent out about food?!
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-64 h-12 lg:mx-0 mx-auto mt-12">
                <PrimaryButton
                  handle={() =>
                    window.open(
                      "https://wa.me/message/S3YZHRWJFVQOL1",
                      "_blank"
                    )
                  }
                >
                  join the clan
                </PrimaryButton>
              </div>
            </div>
            <div className="w-4/12 lg:block hidden">
              <img src={mobileHome} className="w-full object-cover" alt="" />
            </div>
          </div>

          <div className="md:w-3/5 w-full bg-[#FFECE7] rounded-3xl mx-auto py-16 px-4 my-24 relative text-center">
            <p className="text-[#4A4A4A] font-medium">
              Are you a restaurant owner?
            </p>
            <p className="text-[#4A4A4A] my-2 font-medium">
              Do you sell and deliver food to university students?
            </p>
            <p className="text-[#4A4A4A] font-medium">
              Do you enjoy cooking but have problems with delivery?
            </p>
            <div className="w-72 h-12 mx-auto mt-12">
              <PrimaryButton
                handle={() =>
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLScZXPPh_R7BrKAk05DRasKqpLIRV1GDLFO6gbgxLIFSC5FFPQ/viewform?usp=send_form",
                    "_blank"
                  )
                }
              >
                Partner With Us
              </PrimaryButton>
            </div>
            <div className="md:w-24 w-16 absolute right-0 bottom-0 md:-mr-12 mr-0 md:-mb-8">
              <img src={pea} className="w-full object-cover" alt="" />
            </div>
          </div>
        </div>

        <div className="w-full bg-homeHeroImage bg-cover h-64 my-16 text-center py-12">
          <div className="lg:w-4/12 w-10/12 mx-auto">
            <h2 className="text-3xl font-bold text-[#FFFFFF]">As e dey hot!</h2>
            <p className="text-[#FFFFFF]">
              Subscribe to our newsletter and be the first to get the hot gist
              of the dish
            </p>
            <input
              name="email"
              type="email"
              placeholder="Email Address"
              onChange={handleInputChange}
              className="bg-lightGrey outline-none w-3/5 text-sm rounded-full py-2 px-6 mt-4"
            />
            <div className="w-36 h-10 inline-block -ml-6 mb-4">
              <PrimaryButton
                handle={subscribeUser}
                isButtonLoading={loadingsubscribeApi}
              >
                subscribe
              </PrimaryButton>
            </div>
          </div>
        </div>

        <div className="pt-24 relative" id="contact">
          <div className="absolute left-0 lg:bottom-12 hidden lg:block w-24">
            <img src={lettuce} className="w-full object-cover" alt="" />
          </div>
          <div className="lg:w-9/12 w-11/12 mx-auto">
            <div className="flex lg:flex-row w-full flex-col justify-between items-center ">
              <div className="w-full lg:w-5/12 lg:text-left text-center mb-8">
                <div className="">
                  <div className="w-24 mx-auto lg:mx-0">
                    <img
                      src={femaleMoji}
                      className="w-full object-cover"
                      alt=""
                    />
                  </div>
                  <h2 className="text-3xl text-dark font-bold">Hello!</h2>
                  <p className="text-[#4A4D53]">Let’s get in touch</p>
                </div>
                <div className="mt-16">
                  <h3 className="text-[#7E8187] text-sm">Address</h3>
                  <p className="mt-6 text-[#4A4D53] font-bold text-sm">
                    FUTA, Akure Ondo State, Nig.
                  </p>
                </div>
                <div className="mt-16">
                  <h3 className="text-[#7E8187] text-sm">Support</h3>
                  <p className="mt-6 text-[#4A4D53] font-bold text-sm">
                    info@tasteclan.com
                  </p>
                  <p className="text-[#4A4D53] font-bold text-sm">
                    +234 915 862 6091
                  </p>
                </div>
              </div>
              <form
                className="lg:w-6/12 flex flex-col lg:items-end items-center w-full"
                onSubmit={handleSubmit}
                id="reset"
              >
                <div className="w-80 h-12 mb-6">
                  <TextField
                    name="name"
                    type="text"
                    placeholder="Name*"
                    handleInputChange={handleInputChange}
                  />
                </div>
                <div className="w-80 h-12 mb-6">
                  <TextField
                    name="email"
                    type="email"
                    placeholder="Email Address*"
                    handleInputChange={handleInputChange}
                  />
                </div>
                <div className="w-80 h-48 mb-6">
                  <textarea
                    name="message"
                    placeholder="Message*"
                    onChange={handleInputChange}
                    className="outline-none  border-none  rounded-3xl px-4 py-3 h-full w-full bg-[#E8E8E8] focus:outline-primary placeholder:text-sm"
                  ></textarea>
                </div>
                <div className="w-80 h-12 mb-6">
                  <PrimaryButton type="submit" isButtonLoading={loadingMessage}>
                    Send Message
                  </PrimaryButton>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="w-11/12 lg:w-9/12 mx-auto pt-16">
          <div className="flex items-center justify-center gap-4">
            <a href="mailto:info.tasteclan@gmail.com">
              <div className="rounded-full cursor-pointer bg-[#EEEEEE] w-12 h-12 flex items-center justify-center">
                <img src={email} className="w-4 object-cover" alt="" />
              </div>
            </a>
            <a href="https://instagram.com/_taste_clan">
              <div className="rounded-full cursor-pointer bg-[#EEEEEE] w-12 h-12 flex items-center justify-center">
                <img src={instagram} className="w-4 object-cover" alt="" />
              </div>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100076290102833">
              <div className="rounded-full cursor-pointer bg-[#EEEEEE] w-12 h-12 flex items-center justify-center">
                <img src={facebook} className="w-2 object-cover" alt="" />
              </div>
            </a>
            <a href="https://twitter.com/tasteclan">
              <div className="rounded-full cursor-pointer bg-[#EEEEEE] w-12 h-12 flex items-center justify-center">
                <img src={twitter} className="w-4 object-cover" alt="" />
              </div>
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
