import React from "react";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "../pages/ErrorPage";
import Home from "../pages/Home";
import Restaurants from "../pages/Restaurants";
import FeedBack from "../pages/FeedBack";
import Checkout from "../pages/checkout/Checkout";
import Payment from "../pages/checkout/Payment";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import Foodlist from "../pages/Foodlist";
import OrderNotification from "../pages/checkout/OrderConfirmed";
import VerifyPassword from "../pages/authentication/VerifyPassword";
import EmailVerified from "../pages/authentication/EmailVerified";
import OrderHistory from "../pages/orderHistory/OrderHistory";


const TasteClanRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/maintenance" element={<Maintenance />} /> */}
        <Route path="/auth/verify/:id/:emailToken" element={<EmailVerified />} />

        {/* Routes only accessible by signed-in users */}
        <Route element={<AuthenticatedRoutes />}>
          {/* <Route path="/restaurants" element={<TakeABreakPage />} /> */}
          <Route path="/restaurants" element={<Restaurants />} />
          {/* closing the restaurants */}
          <Route path="/foodlist/:restaurantName/:id" element={<Foodlist />} />
          <Route path="/checkout/:restaurantName" element={<Checkout />} />
          <Route path="/checkout/payment" element={<Payment />} />
          <Route path="/feedback/:restaurantName" element={<FeedBack />} />
          <Route path="/checkout/order_notification" element={<OrderNotification />} />
          <Route path="/order_history" element={<OrderHistory />} />
          <Route path="/verify_account" element={<VerifyPassword />} />

        </Route>

        {/* Route for page not found */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default TasteClanRoute;
