import React, { useContext } from "react";
import Navigation from "../Layout/Navigation";
import { FaStar } from "react-icons/fa";
import tickCircle from "../assets/tick-circle.svg";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Rate from "../components/Rate";
import ModalContext from "../contexts/ModalContext";
import AuthContext from "../contexts/AuthContext";
import axios from "axios";

const FeedBack = () => {
  //   const userData = JSON.parse(sessionStorage.getItem("user_data"));
  const { restaurantName } = useParams();
  const { setAlertProps } = useContext(ModalContext);
  const [feedBacks, setFeedbacks] = useState([]);

  const location = useLocation();
  const { accessToken } = useContext(AuthContext);
  // const [rating, setRating] = useState(0)
  const { setIsFeedBackOpen } = useContext(ModalContext);
  const feedbackRatings = feedBacks.map((feedback) => {
    return feedback.rating;
  });
  function average(numbers) {
    // Calculate the sum of the numbers in the array
    let sum = numbers.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    // Divide the sum by the total number of elements in the array
    let avg = sum / numbers.length;

    // Return the average
    return avg;
  }
  const feedbackRatingsResult = average(feedbackRatings);

  useEffect(() => {
    async function fetchFeedbacks() {
      const body = {
        restuarant_id: location.state.restaurantID,
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URI}/vendors/getReviews`,
          body,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        const value = await response?.data?.reviews;
        console.log(value);
        setFeedbacks(value);
      } catch (error) {
        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: error?.response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      }
    }
    fetchFeedbacks();
  }, [accessToken, location.state.restaurantID, setAlertProps]);

  return (
    <>
      <Navigation />
      <section className="pt-24 max-h-screen pb-12 md:w-9/12 w-11/12 mx-auto overflow-hidden">
        <div>
          <h2 className="text-[#A8ABB2] font-semibold text-3xl text-left">
            {restaurantName} Feedback
          </h2>
          <div className="flex items-center justify-start gap-2 mt-4">
            <FaStar className="text-[#FE724E]" size={25} />
            {feedBacks.length !== 0 ? (
              <p className="text-[#A8ABB2]">
                {feedbackRatingsResult} ({feedBacks.length})
              </p>
            ) : (
              <p className="text-[#A8ABB2]">0 (0)</p>
            )}

            <div className="w-2 h-2 rounded-full bg-[#A8ABB2]"></div>
            <h3
              className="text-[#FE724E] font-bold text-xl underline cursor-pointer"
              onClick={() => {
                setIsFeedBackOpen(true);
              }}
            >
              Give Feedback
            </h3>
          </div>
        </div>
        {feedBacks.length !== 0 ? (
          <div className="mt-10 reviews modal-scroll">
            {feedBacks.map((review) => {
              return (
                <div
                  key={review._id}
                  className="flex md:flex-row flex-col gap-4 items-center justify-between py-4 px-4 border-[#CFCFCF] border-b"
                >
                  <div className="flex md:flex-row flex-col gap-4 items-center md:text-left text-center">
                    <div className="w-16 h-16  ">
                      {review?.photo ? (
                        <img
                          src={review?.photo}
                          alt=""
                          className="w-full h-full rounded-full"
                        />
                      ) : (
                        <span className="bg-primary w-full h-full rounded-full flex justify-center items-center text-center leading-10 uppercase text-white font-medium text-2xl select-none ">
                          <p>{review?.username?.charAt(0)}</p>
                        </span>
                      )}
                    </div>
                    <div>
                      {/* <Rate rating={rating} onRating={rate => setRating(rate)}/> */}
                      <div className="flex justify-center md:justify-start mb-2">
                        <Rate rating={review.rating} />
                      </div>
                      <h3 className="font-bold text-lg text-[#282B30]">
                        Satisfactory
                      </h3>
                      <p className="text-lg text-[#7b7b7b] font-normal">
                        {review.content}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <img src={tickCircle} alt="" />
                    <p className="text-lg text-[#7b7b7b] font-normal">
                      Verified Order
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="text-[#A8ABB2] text-2xl">
            Be the first to give us a rating
          </p>
        )}
      </section>
    </>
  );
};

export default FeedBack;
