import React, { useContext, useEffect, useState } from "react";
import PrimaryButton from "../../components/form/PrimaryButton";
import { ReactComponent as Envelope } from "../../assets/envelope-icon.svg";
import TextField from "../../components/form/TextField";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import ModalContext from "../../contexts/ModalContext";

const ForgotPassword = () => {
  // modal context
  const { setAlertProps, setIsForgotPasswordOpen, setIsRecoverPasswordOpen } =
    useContext(ModalContext);

  const [isFormValid, setIsFormValid] = useState(false);
  const [inputValues, setInputValues] = useState({
    email: "",
  });

  useEffect(() => {
    if (inputValues.email) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [inputValues]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate();
  };

  // function sign-in existing user with email and password
  const { mutate, isLoading } = useMutation(
    () => {
      const payload = {
        email: inputValues.email,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URI}/auth/forgot-password`,
        payload
      );
    },
    {
      onSuccess: (response) => {
        //console.log(response);
        setIsForgotPasswordOpen(false);
        setIsRecoverPasswordOpen(true);

        setAlertProps({
          type: "success",
          title: "Success",
          subtitle: response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
        // alert(response?.data?.message);
      },
      onError: (error) => {
       // console.log(error);

        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: error?.response?.data?.message,
        });
        // alert(error?.response?.data?.message);
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
        // alert(error?.response?.data?.message);
      },
      onMutate: () => {},
      onSettled: () => {},
    }
  );

  return (
    <>
      <div className="bg-[#F2F2F2] w-[300px] px-5 py-7 rounded-[36px] md:w-[650px]">
        <h1 className="text-2xl text-center text-[#282B30] font-bold mb-1 md:text-3xl">
          Forgot Password
        </h1>
        <h2 className="text-center text-[#282B30] mb-12">
          Fill your email address to reset
        </h2>

        <form className="md:w-[400px] mx-auto">
          <div className="w-full h-14 mb-9">
            <TextField
              name="email"
              type="text"
              placeholder="Enter email address"
              handleInputChange={handleInputChange}
              value={inputValues.email}
              icon={<Envelope />}
            />
          </div>

          <div className="w-44 h-14 mx-auto mb-10">
            <PrimaryButton
              handle={handleSubmit}
              isButtonDisabled={!!isFormValid}
              isButtonLoading={isLoading}
            >
              send
            </PrimaryButton>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
