import React, { useMemo, useState } from 'react'
import PropTypes from "prop-types";
import { FaStar } from 'react-icons/fa'

const Rate = ({ count, rating, color, onRating}) => {
    const [hoverRating, setHoverRating] = useState(0)
    const getColor = index => {
        if(hoverRating >= index) {
            return color.filled;
        } else if(!hoverRating && rating >= index) {
            return color.filled;
        }

        return color.unfilled
    }

    const starRating = useMemo(() => {
        return Array(count)
        .fill(0)
        .map((_, i) => i + 1)
        .map(idx => 
            <FaStar 
                key={idx} 
                className="cursor-pointer" 
                size={24} 
                onClick={() => onRating(idx)}
                style={{color: getColor(idx)}}
                onMouseEnter={() => setHoverRating(idx)}
                onMouseLeave={() => setHoverRating(0)}
            />
        )
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, rating])

  return (
    <div className='flex items-center gap-2'>
        {starRating}
    </div>
  )
}

Rate.propTypes = {
    count: PropTypes.number,
    rating: PropTypes.number,
    color: {
        filled: PropTypes.string,
        unfilled: PropTypes.string
    },
    onRating: PropTypes.func,
};

Rate.defaultProps = {
    count: 5,
    rating: 0,
    color: {
        filled: '#FE724E',
        unfilled: '#DCDCDC'
    }
}

export default Rate