import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowUp } from "../assets/Arrowup.svg";

const BackToTop = () => {
  const [scrollUp, setScrollUp] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 800) {
        setScrollUp(true);
      } else {
        setScrollUp(false);
      }
    });
  }, []);

  //   function to scroll up
  function toTop() {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

  return (
    <>
      {scrollUp && (
        // <div  className="top-btn" onClick={toTop}>
        // </div>
        <div
          onClick={toTop}
          className="rounded-full fixed right-2 bottom-2 bg-primary w-12 h-12 flex items-center justify-center cursor-pointer hover:bg-[#BE391C]"
        >
          <ArrowUp />
        </div>
      )}
    </>
  );
};

export default BackToTop;
