import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../components/form/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import axios from 'axios'
//import Navigation from '../../Layout/Navigation'

const { REACT_APP_BASE_URI } = process.env
const EmailVerified = () => {
    const navigate = useNavigate()
    const [responseText, setResponseText] = useState("")

    const { id, emailToken } = useParams()
    const verifiedURL = `${REACT_APP_BASE_URI}/auth/verify/${id}/${emailToken}`

    //const dataFetchedRef = useRef(false)

    useEffect(() => {

        axios.get(verifiedURL)
        .then((response) => {
            console.log(response)
            setResponseText(response.response.data.message)
        })
        .catch((error) => {
            //console.log(error.response)
            setResponseText((error.response.data.message))
            console.log(responseText)
        })


        return () => {

        }
    }, [id, emailToken, verifiedURL, responseText])

    return (
        <React.Fragment>
            <main className='flex justify-center items-center h-screen'>
                <div className='bg-[#F2F2F2]  px-5 py-7 rounded-[36px] md:w-[650px]'>
                <h1 className="text-2xl text-center text-[#282B30] font-bold mb-1 md:text-3xl">{responseText}</h1>
                <h2 className="text-center text-[#282B30] mb-12">You can now explore the page!</h2>

                <form className="md:w-[400px] mx-auto">
                    <div className="w-full h-14 mb-10">
                        <PrimaryButton
                        handle={() => navigate('/')}
                        >
                        HOME PAGE
                        </PrimaryButton>
                    </div>
                </form>
            </div>
            </main>

        </React.Fragment>
    )
}

export default EmailVerified
