import React, { useContext, useEffect } from "react";
import Navigation from "../Layout/Navigation";
import heart from "../assets/heart.svg";
import timer from "../assets/timer.svg";
import forkandknife from "../assets/forkandknife.svg";
import close from "../assets/close.png";
// import deliverHero from "../assets/deliverHero.svg";
import star from "../assets/star.svg";
import { useState } from "react";
import axios from "axios";
import PrimaryButton from "../components/form/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ModalContext from "../contexts/ModalContext";
import AuthContext from "../contexts/AuthContext";
import { openingHours } from "../utils/opening_hours";

const Restaurants = () => {
  // modal context
  const { setAlertProps, setIsPreloaderOpen } = useContext(ModalContext);
  // auth context
  const { accessToken } = useContext(AuthContext);
  const [openRestaurants, setOpenRestaurants] = useState(true);
  const [filterMode, setFilterMode] = useState('all')

  let navigate = useNavigate();

  const [searchVendors, setsearchVendors] = useState("");

  //search for vendors
  const OnsearchVendors = (e) => {
    setsearchVendors(e.target.value);
  };

  const fetchFoodItems = async (restaurant) => {
    const payload = {
      restuarant_id: restaurant._id,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/vendors/fetch_restuarant_foods`,
        payload,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      const editFood = await response?.data?.data;
      editFood.forEach((food) => {
        return (food.quantity = 1);
      });
      editFood.forEach((food) => {
        return (food.active = false);
      });
      sessionStorage.setItem("foodItems", JSON.stringify(editFood));
      //  --> example for preorder feature blah blah blah
      sessionStorage.setItem("restaurantData", JSON.stringify(response?.data))
      //  end of implementation for this file
      navigate(`/foodlist/${restaurant.restuarant_name}/${restaurant._id}`, {
        state: {
          monday: restaurant.schedule[0].monday,
          tuesday: restaurant.schedule[0].tuesday,
          wednesday: restaurant.schedule[0].wednesday,
          thursday: restaurant.schedule[0].thursday,
          friday: restaurant.schedule[0].friday,
          saturday: restaurant.schedule[0].saturday,
          sunday: restaurant.schedule[0].sunday,
        },
      });
    } catch (error) {
      setAlertProps({
        type: "fail",
        title: "Error",
        subtitle: error?.response?.data?.message,
      });
      setTimeout(() => {
        setAlertProps({
          type: "",
          title: "",
          subtitle: null,
        });
      }, 5000);
    }
  };

  // Get restaurant data from api

  const fetchRestaurants = () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URI}/vendors/fetch_all_restaurants`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  };

  // Query to fetch list of restuarants
  const { data: restaurants, isLoading } = useQuery(
    ["restaurants-list"],
    fetchRestaurants,
    {
      onError: (error) => {
        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: error?.response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      },
    }
  );

  // Show preloader while fetching restaurant data
  useEffect(() => {
    const date = new Date();
    const hours = date.getHours();
    const dayName = date.getDay()
    hours >= openingHours.startTime && hours <= openingHours.endTime && dayName !== 0
      ? setOpenRestaurants(true)
      : setOpenRestaurants(false);
    if (isLoading) {
      setIsPreloaderOpen(true);
    } else {
      setIsPreloaderOpen(false);
    }
  }, [isLoading, setIsPreloaderOpen]);

  const onFilterByPreOrder = (e) => {
    setFilterMode(e.target.value);
  }

  const Vendors = restaurants?.data?.data?.filter((restaurant) => {
    switch (filterMode.toLowerCase()) {
      case 'all':

        return true;
      case 'pre-order':

        return restaurant?.preorder === true;
      case 'normal':

        return !restaurant?.preorder;
      default:
        return true;
    }
  }).filter((restaurant) => {
    return searchVendors === "" || restaurant?.restuarant_name?.toLowerCase().includes(searchVendors.toLowerCase());
  });

  if (!openRestaurants) {
    return (
      <>
        <Navigation />
        <section className="pt-24 h-screen pb-12">
          <div className="md:w-8/12 w-11/12 mx-auto flex flex-col items-center justify-center h-full">
            <div className="md:w-6/12 w-9/12 mx-auto -mt-12">
              <img src={close} className="w-full object-cover" alt="" />
            </div>
            <div className="lg:-mt-40 -mt-20">
              <h2 className="text-4xl text-[#7B7B7B] text-center font-medium">
                We are currently not open.
              </h2>
              <p className="text-xl w-10/12 mx-auto text-[#7B7B7B] mb-8 mt-4">
                Oops, we are not open at the moment. Check in with us another
                time.{" "}
              </p>
              <div className="w-72 h-16 mx-auto">
                <PrimaryButton handle={() => navigate("/")}>
                  Home page
                </PrimaryButton>
              </div>
            </div>
            {/* <div className="">
              <h2 className="text-4xl text-[#7B7B7B] text-center font-medium">
                We took a break for you to <span class="curve-underline relative">deliver.</span>
              </h2>
              <p className="text-xl w-10/12 mx-auto text-[#7B7B7B] mb-8 mt-4">
              Deliver happiness to your country by exercising your civic duties to vote.
              </p>
              <div className="w-72 h-16 mx-auto">
                <PrimaryButton handle={() => navigate("/")}>
                  Home page
                </PrimaryButton>
              </div>
            </div> */}
          </div>
        </section>
      </>
    );
  } else {
    return (
      <>
        <Navigation />
        <section className="pt-24 min-h-screen pb-12">
          <div className="lg:w-5/6 w-11/12 mx-auto">
            <div className="flex items-center md:flex-row flex-col gap-6 justify-between mt-8">
              <div>
                <h2 className="font-bold text-3xl">Restaurants</h2>
                <p className="mt-2">Vendors in your area</p>
              </div>
              <div className="flex items-end space-x-6 md:space-x-3 ">
                {/* filter and search */}
                <input
                  type="text"
                  placeholder="🔍Search Vendor..."
                  className="bg-transparent outline-none border-b-2 border-current pb-2 text-sm"
                  onChange={OnsearchVendors}
                />

                <div>
                  <label className="block mb-2 text-start text-sm font-medium text-gray-900">Filter By</label>
                  <select onChange={onFilterByPreOrder} className="bg-transparent border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 ">
                    <option>All</option>
                    <option>Normal</option>
                    <option>Pre-order</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 justify-items-center mt-8">
              {Vendors?.map((restaurant) => {
                return (
                  <div
                    key={restaurant._id}
                    className="border-primary border mt-40 px-4 py-6 grid justify-center items-center relative rounded-2xl w-80 bg-offWhite"
                  >
                    <div>
                      <div className="w-44 h-44 rounded-full mx-auto -mt-32">
                        <img
                          src={restaurant?.restuarant_image || forkandknife}
                          className="w-full h-full rounded-full object-cover"
                          alt=""
                        />
                      </div>
                      <div className="w-6 h-6 rounded-full flex items-center justify-center absolute right-4 top-6 bg-primary">
                        <img src={heart} className="w-3 object-cover" alt="" />
                      </div>
                      {/* <div className="flex justify-center gap-2 my-1 items-center">
                      <div className="w-3 h-3 rounded-full bg-primary"></div>
                      <p>Online</p>
                    </div> */}
                      <div className="w-4/6 mx-auto mt-3">
                        <h3 className="text-lg text-dark font-bold">
                          {restaurant?.restuarant_name}
                        </h3>
                        <p className="my-8 text-sm">
                          Enjoy freshly cooked food delivered to you.
                        </p>
                      </div>
                      <div className="flex justify-center gap-2 items-center">
                        <div className="w-4 h-4 rounded-full flex items-center justify-center bg-light">
                          <img src={star} className="w-2 object-cover" alt="" />
                        </div>
                        <p className="text-sm text-[#4A4D53]">5.0</p>
                        <div className="w-1 h-1 rounded-full bg-[#A8ABB2]"></div>
                        <div className="w-5 h-5 rounded-full flex items-center justify-center bg-light">
                          <img
                            src={timer}
                            className="w-3 object-cover"
                            alt=""
                          />
                        </div>
                        <p className="text-[#4A4D53] text-sm">
                          {restaurant?.schedule[0]?.opening_time} -{" "}
                          {restaurant?.schedule[0]?.closing_time}
                        </p>


                      </div>
                      <p className="text-[#4A4D53] text-sm">
                        {restaurant?.preorder ? "Items will be Preordered" : ""}
                      </p>
                      <div className="w-48 mx-auto h-12 mt-4">
                        <PrimaryButton
                          handle={() => fetchFoodItems(restaurant)}
                        >
                          view details
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Restaurants;
