import React from "react";
import PropTypes from "prop-types";

const PrimaryButton = ({
  color = "primary",
  handle,
  isButtonDisabled,
  isButtonLoading,
  children,
  type = "button",
}) => {
  return (
    <button
      onClick={handle}
      type={type}
      disabled={isButtonDisabled}
      className={` w-full h-full p-2 border-none outline-none font-medium text-white  text-center rounded-[40px] mx-auto text-sm  block uppercase tracking-wide  transition duration-200 select-none
      ${
        color === "primary" &&
        "bg-primary disabled:bg-primary/50 hover:bg-[#BE391C]"
      }
      ${
        color === "secondary" &&
        "bg-secondary disabled:bg-secondary/50 hover:bg-[#c79820]"
      }
       
      `}
    >
      {isButtonLoading ? (
        <svg
          className="mx-auto animate-spin"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 1.66671C8.35186 1.66671 6.7407 2.15545 5.37029 3.07113C3.99988 3.9868 2.93177 5.28829 2.30104 6.81101C1.67031 8.33373 1.50529 10.0093 1.82683 11.6258C2.14837 13.2423 2.94204 14.7272 4.10748 15.8926C5.27292 17.058 6.75778 17.8517 8.37429 18.1733C9.99079 18.4948 11.6664 18.3298 13.1891 17.699C14.7118 17.0683 16.0133 16.0002 16.929 14.6298C17.8446 13.2594 18.3334 11.6482 18.3334 10"
            stroke="#F9F9F9"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        children
      )}
    </button>
  );
};
PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  isButtonDisabled: PropTypes.bool,
  handle: PropTypes.func.isRequired,
};

export default PrimaryButton;
