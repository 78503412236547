import React, { useContext, useEffect, useState } from "react";
import Logo from "../assets/Logo 1.svg";
import {
  // Navigate,
  NavLink,
  useNavigate,
  // useSearchParams,
} from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
//import { BlurModal, OpaqueModal } from "./Modal";
import { HamburgerMenu } from "../components/HamburgerMenu";
import ModalContext from "../contexts/ModalContext";
// import PrimaryButton from "../components/form/PrimaryButton";
// import { ReactComponent as ArrowDown } from "../assets/dropdown-arrow.svg";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { HashLink } from "react-router-hash-link";

const Navigation = () => {
  // modal context
  const {
    setAlertProps,
    setIsLoginModalOpen,
    setIsRegisterModalOpen,
    setIsPreloaderOpen,
  } = useContext(ModalContext);

  let navigate = useNavigate();
  const [isHamburgerOpen, setisHamburgerOpen] = useState(false);
  // const [showLogOut, setShowLogOut] = useState(false);

  const userLoggedIn = sessionStorage.getItem("access_token");
  // const userData = JSON.parse(sessionStorage.getItem("user_data"));

  const closeHamburger = () => {
    setisHamburgerOpen(false);
  };

  const openHamburger = () => {
    setisHamburgerOpen(true);
  };

  const signUpModal = () => {
    setIsRegisterModalOpen(true);
    setIsLoginModalOpen(false);
  };

  // Queries
  const { refetch: logOut, isRefetching } = useQuery(
    ["log-out-user"],
    () => {
      return axios.get(`${process.env.REACT_APP_BASE_URI}/auth/logout`, {
        headers: { Authorization: `Bearer ${userLoggedIn}` },
      });
    },
    {
      enabled: false,
      onSuccess: (response) => {
        sessionStorage.removeItem("refresh_token");
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("user_data");
        navigate("/");
      },
      onError: (error) => {
        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: error?.response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      },
      retry: false,
    }
  );
  // Show preloader while fetching restaurant data
  useEffect(() => {
    if (isRefetching) {
      setIsPreloaderOpen(true);
    } else {
      setIsPreloaderOpen(false);
    }
  }, [setIsPreloaderOpen, isRefetching]);

  return (
    <header className="w-full py-5 fixed z-50 bg-body top-0 ">
      <div className="lg:w-5/6 w-11/12 mx-auto">
        <nav className="flex flex-row justify-between items-center">
          <RxHamburgerMenu
            className="lg:hidden block text-3xl"
            onClick={openHamburger}
          />
          <div className="w-28 pointer">
            <img src={Logo} className="w-full object-cover" alt="" />
          </div>
          <div className="lg:flex hidden flex-row gap-4">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "link-active" : "link")}
            >
              <li className=""> Home</li>
            </NavLink>
            {/* {userLoggedIn && ( */}
            <NavLink
              to="/restaurants"
              className={({ isActive }) =>
                isActive ? "link-active" : "link"
              }
            >
              <li className=""> Restaurants</li>
            </NavLink>
            {/* )} */}
            <HashLink to="/#joinClan" className="link">
              <li className=""> Join Clan</li>
            </HashLink>
            <HashLink to="/#contact" className="link">
              <li className="">Contact</li>
            </HashLink>
          </div>
          {/* {!userLoggedIn ? ( */}
          <div className="flex items-center gap-6">
            {/* <p
                className="cursor-pointer"
                onClick={() => {
                  setIsRegisterModalOpen(false);
                  setIsLoginModalOpen(true);
                }}
              >
                {" "}
                Login
              </p> */}

            {/* <span className="w-40 h-12 hidden md:inline-block">
                <PrimaryButton
                  color="secondary"
                  handle={() => {
                    setIsRegisterModalOpen(true);
                    setIsLoginModalOpen(false);
                  }}
                >
                  Sign Up
                </PrimaryButton>
              </span> */}
          </div>
          {/* ) : ( */}
          <div className="flex items-center gap-3">
            {/* <div className="w-10 h-10  ">
                {userData?.photo ? (
                  <img
                    src={userData?.photo}
                    alt=""
                    className="w-full h-full rounded-full"
                  />
                ) : (
                  <span className="bg-primary w-full h-full rounded-full inline-block text-center leading-10 uppercase text-white font-medium text-xl select-none ">
                    {userData?.username?.charAt(0)}
                  </span>
                )}
              </div> */}
            <div className="relative hidden md:block">
              {/* <div
                  onClick={() => setShowLogOut(!showLogOut)}
                  className="p-2 flex justify-center items-center gap-2 cursor-pointer"
                >
                  <span className=" select-none capitalize">
                    {userData?.username}
                  </span>
                  <ArrowDown />
                </div> */}
              {/* {showLogOut && (
                  <button
                    disabled={isRefetching}
                    className="cursor-pointer absolute top-10 right-0 bg-white p-2 min-w-full border-b border-l border-r  rounded-br-xl rounded-bl-xl border-primary hover:bg-[#FFF1F1] "
                    onClick={() => logOut()}
                  >
                    {" "}
                    Logout
                  </button>
                )} */}
            </div>
          </div>
          {/* )} */}
        </nav>
      </div>

      {/* Hamburger Menu */}
      <HamburgerMenu
        userLoggedIn={userLoggedIn}
        closeHamburger={closeHamburger}
        isHamburgerOpen={isHamburgerOpen}
        signUpModal={signUpModal}
        logOut={logOut}
      />
    </header>
  );
};

export default Navigation;
