import React, { useState, useContext } from "react";
import Rate from "./Rate";
import { useLocation } from "react-router-dom";
import PrimaryButton from "./form/PrimaryButton";
import ModalContext from "../contexts/ModalContext";
import axios from "axios";

const FeedBackModal = () => {
  const [rating, setRating] = useState(4);
  const location = useLocation();
  const { setAlertProps } = useContext(ModalContext);
  const userData = JSON.parse(sessionStorage.getItem("user_data"));
  const [inputValues, setInputValues] = useState({});
  const accessToken = sessionStorage.getItem("access_token");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const addReviews = async () => {
    const body = {
      restuarant_id: location.state.restaurantID,
      rating: rating,
      username: userData.username,
      content: inputValues.message,
      title: "title is not neccessary enjoy this dummy",
    };
    console.log(body);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URI}/vendors/createReviews`,
        body,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setAlertProps({
        type: "success",
        title: "Success",
        subtitle: "FeedBack Sent",
      });
      setTimeout(() => {
        setAlertProps({
          type: "",
          title: "",
          subtitle: null,
        });
      }, 5000);
    } catch (error) {
      setAlertProps({
        type: "fail",
        title: "Error",
        subtitle: error?.response?.data?.message,
      });
      setTimeout(() => {
        setAlertProps({
          type: "",
          title: "",
          subtitle: null,
        });
      }, 5000);
    }
    window.location.reload();
  };

  return (
    <React.Fragment>
      <div className="bg-[#F2F2F2] px-5 py-7 w-[300px] rounded-[36px] md:w-[500px] md:my-auto my-20 mx-auto ">
        <div className="flex flex-col items-center md:w-8/12 w-11/12 mx-auto text-center">
          <h3 className="text-2xl font-bold mb-2">Give Feedback</h3>
          <p className="mb-8">
            The feedback system helps the restaurant improve on their services{" "}
          </p>
          <Rate
            rating={rating}
            onRating={(rate) => {
              setRating(rate);
              console.log(rate);
            }}
          />
          <div className="w-full h-48 mt-12">
            <textarea
              name="message"
              onChange={handleInputChange}
              placeholder="Message*"
              className="outline-none border-none rounded-3xl px-4 py-3 h-full w-full bg-[#E8E8E8] focus:outline-primary placeholder:text-sm"
            ></textarea>
          </div>
          <div className="w-full h-12 mt-4">
            <PrimaryButton type="submit" handle={addReviews}>
              SUBMIT
            </PrimaryButton>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FeedBackModal;
