import {React } from 'react'

const Counter = (props) => {
      return (
        <div className='flex items-center'>
        <svg onClick={props.decrease}   width={props.width} height={props.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <rect width="24" height="24" rx="12" fill="#E8E8E8"/>
         <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 11.5H15.5C15.7761 11.5 16 11.7239 16 12C16 12.2761 15.7761 12.5 15.5 12.5H8.5C8.22386 12.5 8 12.2761 8 12C8 11.7239 8.22386 11.5 8.5 11.5Z" fill="#282B30"/>
         </svg>
         
         <h2 className='mx-2 text-base'>{props.counter}</h2>
    
    
         <svg onClick={props.increase} width={props.width} height={props.height}  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <rect width="24" height="24" rx="12" fill="#E8E8E8"/>
         <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8C12.2761 8 12.5 8.22386 12.5 8.5V11.5H15.5C15.7761 11.5 16 11.7239 16 12C16 12.2761 15.7761 12.5 15.5 12.5H12.5V15.5C12.5 15.7761 12.2761 16 12 16C11.7239 16 11.5 15.7761 11.5 15.5V12.5H8.5C8.22386 12.5 8 12.2761 8 12C8 11.7239 8.22386 11.5 8.5 11.5H11.5V8.5C11.5 8.22386 11.7239 8 12 8Z" fill="#282B30"/>
         </svg>
         {/* <p className='ml-2 text-xs'>{price}</p> */}
        </div>
      )
}

export default Counter

// read me 
// i create this componets and allowed it to use props so that anyone that need to resize the component can do that easily