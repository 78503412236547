import "./App.css";
import TasteClanRoute from "./routes/TasteClanRoute";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ModalProvider } from "./contexts/ModalContext";
import BackToTop from "./components/BackToTop";
import { AuthProvider } from "./contexts/AuthContext";

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <ModalProvider>
          <AuthProvider>
            <TasteClanRoute />
            <BackToTop />
          </AuthProvider>
        </ModalProvider>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
