import React, { useContext, useEffect, useState } from "react";
import PrimaryButton from "../../components/form/PrimaryButton";
import { ReactComponent as Lock } from "../../assets/lock-icon.svg";
import TextField from "../../components/form/TextField";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import ModalContext from "../../contexts/ModalContext";

const RecoverPassword = () => {
  // modal context
  const { setAlertProps, setIsLoginModalOpen, setIsRecoverPasswordOpen } =
    useContext(ModalContext);

  const [isFormValid, setIsFormValid] = useState(false);
  const [inputValues, setInputValues] = useState({
    code: "",
    password: "",
    confirm_password: "",
  });

  useEffect(() => {
    if (inputValues.code) {
      if (inputValues.password === inputValues.confirm_password) {
        //console.log("password maatch");
        setIsFormValid(false);
      }
    } else {
      //console.log("form in invalid");
      setIsFormValid(true);
    }
  }, [inputValues]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate();
  };

  // function sign-in existing user with email and password
  const { mutate, isLoading } = useMutation(
    () => {
      const payload = {
        token: inputValues.code,
        password: inputValues.password,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URI}/auth/reset-password`,
        payload
      );
    },
    {
      onSuccess: (response) => {
        setIsRecoverPasswordOpen(false);
        setIsLoginModalOpen(true);

        setAlertProps({
          type: "success",
          title: "Success",
          subtitle: response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      },
      onError: (error) => {
        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: error?.response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      },
      onMutate: () => {},
      onSettled: () => {},
    }
  );

  return (
    <>
      <div className="bg-[#F2F2F2]  px-5 py-7 rounded-[36px] md:w-[650px]">
        <h1 className="text-2xl text-center text-[#282B30] font-bold mb-1 md:text-3xl">
          Reset Password?
        </h1>
        <h2 className="text-center text-[#282B30] mb-12">
          Fill in to reset to a new password
        </h2>
        <p onClick={() => mutate()} className="hidden"></p>

        <form className="md:w-[400px] mx-auto">
          <div className="w-full h-14 mb-9">
            <TextField
              name="code"
              type="text"
              placeholder="Enter verification code"
              handleInputChange={handleInputChange}
              value={inputValues.code}
              icon={<Lock />}
            />
          </div>
          <div className="w-full h-14 mb-9">
            <TextField
              name="password"
              type="password"
              placeholder="Enter password"
              handleInputChange={handleInputChange}
              value={inputValues.password}
              icon={<Lock />}
            />
          </div>
          <div className="w-full h-14 mb-9">
            <TextField
              name="confirm_password"
              type="password"
              placeholder="Confirm password"
              handleInputChange={handleInputChange}
              value={inputValues.confirm_password}
              icon={<Lock />}
            />
          </div>

          <div className="w-44 h-14 mx-auto mb-10">
            <PrimaryButton
              handle={handleSubmit}
              isButtonDisabled={!!isFormValid}
              isButtonLoading={isLoading}
            >
              reset
            </PrimaryButton>
          </div>
        </form>
      </div>
    </>
  );
};

export default RecoverPassword;
